<template>
  <main>
    <Loading v-if="isLoading"></Loading>
    <div v-else>
      <h2 class="my-2 font-semibold text-blue-800 text-2xl page-title">
        Password Reset Link
      </h2>
      <!-- <div class="my-10 flex items-center">
          <input
            type="text"
            class="inline-block px-2 ring-2 outline-none ring-blue-500 rounded-sm ml-2 w-64 text-sm py-2"
            name=""
            placeholder="Search"
            id=""
            v-model="search"
          />
          <button
            @click="
              $router.push({
                name: 'handleSearch',
              })
            "
            class="block uppercase shadow ml-2 bg-blue-600  leading-6 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white text-xs py-2 px-8 rounded"
          >
            Search
          </button>
        </div> -->

      <div class="overflow-scroll pb-6">
        <table class="shadow-lg bg-white w-full">
          <thead>
            <tr>
              <th
                class="text-l p-3 text-left"
                colspan="13"
                style="background: #DEE6F2;"
              >
                Recent Links
              </th>
            </tr>
            <tr>
              <th
                class="bg-blue-100  text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Business ID
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Business Name
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Email Address
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Date
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Expires At
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Reset Link
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(link, i) in links" :key="i" class="hover:bg-gray-300">
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ link.business_id || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ link.business_name || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ link.email || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ link.created_at || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ link.expires_at || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                <input
                  type="text"
                  ref="clone"
                  v-on:focus="$event.target.select()"
                  :id="link.business_id"
                  :value="link.recovery_link"
                  class="p-2"
                />
                <button
                  @click="copyUrl(link)"
                  class="block uppercase shadow bg-blue-600 inline-block leading-6 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white text-xs px-2 py-1 rounded"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-front inline-block"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M0 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v2h2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-2H2a2 2 0 0 1-2-2V2zm5 10v2a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1h-2v5a2 2 0 0 1-2 2H5z"
                    />
                  </svg>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </main>
</template>

<script>
import moment from "moment";
import Loading from "./general-components/Loading.vue";

export default {
  name: "PasswordReset",
  data() {
    return {
      isLoading: false,
      search: "",
      moment,
      links: [],
    };
  },
  components: {
    Loading,
  },
  filters: {
    truncate: function(text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
  methods: {
    async fetchResetLink() {
      this.isLoading = true;
      try {
        let res = await this.$store.dispatch("FETCH_RESET_LINK");
        this.links = res.data;
        this.isLoading = false;
      } catch (error) {
        console.log(error, "error");
      }

      this.isLoading = false;
    },
    handleCopy(link) {
      console.log(link.recovery_link);
      let valueToBeCopied = link.recovery_link;
      valueToBeCopied.select();
      // this.$refs.clone.focus();
      document.execCommand("copy");
      alert("Copied");
    },
    copyUrl(link) {
      const el = document.createElement("textarea");
      el.value = link.recovery_link;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      const selected =
        document.getSelection().rangeCount > 0
          ? document.getSelection().getRangeAt(0)
          : false;
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      if (selected) {
        alert("Copied!");
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
      }
    },
  },
  async mounted() {
    await this.fetchResetLink();
  },
};
</script>

<style scoped>
table {
  border-collapse: separate;
  border-spacing: 0;
  min-width: 350px;
}
table tr th:last-child {
  border-right: 1px solid rgb(241, 241, 241);
}
table tr th,
table tr td {
  /* border-right: 1px solid #bbb; */
  border-bottom: 1px solid rgb(238, 238, 238);
  padding: 18px;
}
table tr th:first-child,
table tr td:first-child {
  /* border-right: 1px solid rgb(223, 223, 223); */
  border-left: 1px solid rgb(241, 241, 241);
}
table tr th {
  background: rgb(255, 255, 255);
  border-top: 1px solid rgb(241, 241, 241);
  text-align: left;
}
table tr td {
  background: #f0f6fd;
}
/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
</style>
